<script setup lang="ts">
const { isAuthenticated } = useAuth()
</script>

<template>
  <header class="dark:bg-muted-800 bg-white">
    <Container
      class="border-b-1 border-b-muted-500 flex flex-row-reverse justify-between border-b pb-1 pt-3 md:flex-row md:border-b-0"
    >
      <div class="flex h-16 w-full items-start gap-x-4">
        <NuxtLink to="/">
          <PublicLogo class="h-16 pb-3" />
        </NuxtLink>
        <HeaderOrganizationSelect />
      </div>

      <div class="hidden items-start gap-3 md:flex">
        <ToolbarLanguage />
        <BaseThemeToggle />
        <ToolbarAccountMenu v-if="isAuthenticated" />
        <ToolbarSignIn v-else />
      </div>

      <TairoSidebarBurger class="visible -ml-2 mt-1 md:hidden" />
    </Container>

    <PublicCmsMenu />
  </header>

  <PublicAuthenticatedMenu v-if="isAuthenticated" />
</template>
