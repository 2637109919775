<script setup lang="ts">
const { name } = withDefaults(
  defineProps<{
    name?: string
    lowOpacity?: boolean
  }>(),
  { lowOpacity: true },
)
</script>

<template>
  <div class="my-4">
    <h3 v-if="name" class="text-md text-muted-100 mb-4 font-semibold lowercase">
      {{ name }}
    </h3>
    <div :class="{ 'opacity-65': lowOpacity }">
      <slot />
    </div>
  </div>
</template>
